import { defineComponent, onMounted, ref } from 'vue';
import AdminPanelLoader from '@/components/panel/panel-components/AdminPanelLoader.vue';
import AdminPanelLotteryHistoryTickets from '@/components/panel/panel-tickets/AdminPanelLotteryHistoryTickets.vue';
import AdminPanelLotteryCurrentTicket from '@/components/panel/panel-tickets/AdminPanelLotteryCurrentTicket.vue';
import { assignNicknameToTicket, getCurrentLotteryTicketAssignment, getHistoryLotteryTicketAssignment } from '@/assets/ts/api/lottery-tickets';
export default defineComponent({
    components: {
        AdminPanelLotteryCurrentTicket,
        AdminPanelLotteryHistoryTickets,
        AdminPanelLoader
    },
    setup() {
        const isLoading = ref(false);
        const activeCard = ref('');
        const currentTicket = ref(null);
        const historyTickets = ref([]);
        const onClickCurrentLottery = () => {
            if (activeCard.value === 'currentTicket') {
                return;
            }
            isLoading.value = true;
            activeCard.value = 'currentTicket';
            getCurrentLotteryTicketAssignment().then((response) => {
                currentTicket.value = response.ticket;
            }).finally(() => isLoading.value = false);
        };
        const onClickHistory = () => {
            if (activeCard.value === 'history') {
                return;
            }
            isLoading.value = true;
            activeCard.value = 'history';
            getHistoryLotteryTicketAssignment().then((response) => {
                historyTickets.value = response.tickets;
            }).finally(() => isLoading.value = false);
        };
        onMounted(() => {
            onClickCurrentLottery();
        });
        const onAssignTicket = (assignedTicket) => {
            assignNicknameToTicket(assignedTicket.lottery_assigned_ticket_id, assignedTicket.nickname);
        };
        return {
            activeCard,
            currentTicket,
            historyTickets,
            isLoading,
            onAssignTicket,
            onClickCurrentLottery,
            onClickHistory,
        };
    }
});
