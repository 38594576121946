import { defineComponent, ref } from 'vue';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
import AdminPanelLotteryCurrentTicket from '@/components/panel/panel-tickets/AdminPanelLotteryCurrentTicket.vue';
import { assignNicknameToTicket } from '@/assets/ts/api/lottery-tickets';
import Modal from '@/components/layout/modal/Modal.vue';
export default defineComponent({
    components: { Modal, AdminPanelLotteryCurrentTicket, CTAButton },
    props: {
        tickets: {
            type: Array,
            default: [],
            required: false
        }
    },
    setup() {
        const lotterySummaryModal = ref();
        const viewedAssignedTicket = ref(null);
        const closeModal = () => {
            lotterySummaryModal.value.toggleModal();
        };
        const toggleEditMode = (assignedTicket) => {
            assignedTicket._editMode = !assignedTicket._editMode;
        };
        const onAssignTicket = (assignedTicket) => {
            assignNicknameToTicket(assignedTicket.lottery_assigned_ticket_id, assignedTicket.nickname);
        };
        const showSummary = (assignedTicket) => {
            viewedAssignedTicket.value = assignedTicket;
            lotterySummaryModal.value.toggleModal();
        };
        return {
            lotterySummaryModal,
            viewedAssignedTicket,
            closeModal,
            onAssignTicket,
            showSummary,
            toggleEditMode
        };
    }
});
