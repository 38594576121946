import { defineComponent } from 'vue';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
export default defineComponent({
    components: { CTAButton },
    props: {
        ticket: {
            type: Object,
            default: null,
            required: false
        }
    },
    emits: ['assign-ticket'],
    setup(props, context) {
        const toggleEditMode = (assignedTicket) => {
            if (assignedTicket._editMode) {
                assignedTicket.lottery_assigned_ticket_id = assignedTicket._editMode.lottery_assigned_ticket_id;
                assignedTicket.lottery_ticket_id = assignedTicket._editMode.lottery_ticket_id;
                assignedTicket.nickname = assignedTicket._editMode.nickname;
                assignedTicket.number = assignedTicket._editMode.number;
                assignedTicket._editMode = null;
            }
            else {
                assignedTicket._editMode = Object.assign({}, assignedTicket);
            }
        };
        const saveAssignedTicket = (assignedTicket) => {
            assignedTicket._editMode = null;
            context.emit('assign-ticket', assignedTicket);
        };
        return {
            saveAssignedTicket,
            toggleEditMode
        };
    }
});
